.home {
  .auth-popup {
    @apply w-screen h-screen absolute bg-black/50 z-10;
    &__wrapper {
      @apply px-20 py-16 absolute h-fit w-1/3 top-1/4 left-1/3 z-20 text-center bg-white rounded-lg drop-shadow-lg;
      .close-button {
        @apply w-10 h-10 absolute -top-3 -right-3 cursor-pointer transition-all text-red-500;
        &:hover {
          @apply scale-105;
        }

        &:active {
          @apply scale-95;
        }
      }

      .success-alert {
        @apply text-emerald-500 text-sm mb-4;
      }

      .input-group {
        @apply mb-5;
        label {
          @apply mb-4 text-xl;
        }

        input {
          @apply mb-2 dark:bg-gray-50 dark:border-gray-300;
        }

        & + small {
          @apply mb-5;
        }
      }

      .subtext {
        @apply mt-6 cursor-default;
        a {
          @apply ms-3 inline-block cursor-pointer text-red-500 underline transition-all;
          &:hover {
            @apply scale-110;
          }
        }
      }
    }
  }
}