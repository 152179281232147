.todo {
  .todo__sidebar {
    @apply bg-gray-100;
  }

  .projects-select {
    @apply w-full mb-4 px-6 py-2 rounded-lg transition-all cursor-pointer bg-white dark:bg-slate-700 shadow-md;
    .custom-select {
      @apply bg-transparent;
    }
  }
}