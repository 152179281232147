.checkbox {
  @apply my-3 flex gap-4 items-center;
  label {
    @apply text-lg text-slate-800 dark:text-gray-100 transition-transform;
    &:hover {
      @apply scale-105;
    }
  }

  input {
    @apply hidden;
  }

  &-icon {
    @apply w-6 h-6 flex items-center justify-center border-4 border-gray-200 dark:border-gray-500 rounded-full bg-transparent cursor-pointer transition-all;
    &:hover {
      @apply bg-gray-200 dark:bg-gray-500;
      .check-icon {
        @apply text-gray-400 dark:text-gray-800;
      }
    }

    &:active {
      @apply scale-90;
    }

    .check-icon {
      @apply text-transparent w-7 h-7;
    }
  }

  input:checked + label {
    @apply bg-green-500 border-green-500 dark:bg-green-600 dark:border-green-600;
    .check-icon {
      @apply text-white dark:text-gray-100;
    }
  }
}