.tasks {
  @apply text-gray-700 dark:text-gray-200;

  h3 {
    @apply mb-10 text-4xl font-bold opacity-40 dark:drop-shadow-lg;
  }

  &__list {
    @apply pb-24;
  }

  &__title {
    $self: &;
    @apply dark:drop-shadow-lg pb-10 mb-8 flex items-center text-5xl leading-tight font-bold border-b-2 border-slate-200 dark:border-slate-500 opacity-90;
    textarea {
      @apply w-full inline-block resize-none transition-all border-2 border-transparent;
      &:focus {
        @apply border-slate-100;
      }
    }
  }

  &__remove-completed {
    small {
      @apply inline-block text-gray-300 dark:text-gray-400 cursor-pointer transition-all;
      &:hover {
        @apply text-red-400;
      }

      &:active {
        @apply text-red-500 scale-95;
      }
    }
  }

  &__items {
    &-row {
      @apply mt-4 flex items-center;

      &:hover {
        .task-delete-icon {
          @apply opacity-50;
        }

        .plus-icon {
          @apply opacity-80 dark:opacity-60 border-4;
        }
      }

      .plus-icon {
        @apply w-10 h-10 border-2 border-gray-300 rounded-full text-gray-400 opacity-40 transition-all cursor-pointer;
      }

      .checkbox {
        input {
          @apply hidden;
        }

        label {
          @apply w-10 h-10 flex items-center justify-center border-4 border-gray-200 dark:border-gray-500 rounded-full bg-transparent cursor-pointer transition-all;
          &:hover {
            @apply bg-gray-200 dark:bg-gray-500;
            .check-icon {
              @apply text-gray-400 dark:text-gray-800;
            }
          }

          &:active {
            @apply scale-90;
          }

          .check-icon {
            @apply text-transparent w-7 h-7;
          }
        }

        input:checked + label {
          @apply bg-green-500 border-green-500 dark:bg-green-600 dark:border-green-600;
          .check-icon {
            @apply text-white dark:text-gray-100;
          }
        }
      }

      .task-text {
        @apply ms-6 py-2 w-full flex-1 text-2xl border-b-2 border-b-transparent transition-all;
        &:focus {
          @apply border-b-gray-100 dark:border-b-gray-500;
        }

        &.new {
          &:focus {
            &::placeholder {
              @apply text-gray-300 dark:text-gray-500;
            }
          }

          &::placeholder {
            @apply text-gray-200 dark:text-gray-400;
          }
        }
      }

      .task-delete-icon {
        @apply ms-4 w-7 h-7 text-red-500 opacity-0 transition-all cursor-pointer;
        &:hover {
          @apply opacity-90;
        }

        &:active {
          @apply scale-75;
        }
      }
    }
  }
}
