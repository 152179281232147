@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
  font-family: "Montserrat", sans-serif, -apple-system;
  box-sizing: border-box;
  background-color: transparent;
}

body {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.container {
  width: 1420px;
  @apply my-0 mx-auto h-full;
}

.todo {
  @apply flex h-screen text-slate-800 dark:text-gray-100;
  .rcs-inner-handle {
    @apply bg-gray-300 dark:bg-slate-800;
  }

  .sidebar-container {
    @apply bg-gray-100 dark:bg-slate-600;
  }

  &__sidebar {
    @apply py-16 px-14 max-h-screen dark:bg-transparent;
    &__inner {
      @apply w-full h-full;
    }
  }

  .tasks-container {
    @apply bg-white dark:bg-slate-700;
  }

  &__tasks {
    @apply p-24 flex-1 max-h-screen;
  }
}

.loading {
  @apply h-screen flex flex-col items-center justify-center dark:bg-slate-600;
  &-icon {
    @apply mb-16 w-72 h-72 text-slate-500 dark:text-gray-300 animate-spin;
  }

  &-text {
    @apply text-3xl font-bold text-slate-700 dark:text-gray-200;
  }
}

.logout {
  @apply flex items-center gap-2 absolute top-4 right-4;
  &__username {
    @apply text-slate-800 dark:text-gray-100;
  }

  &__button {
    @apply w-10 h-10 cursor-pointer transition-all text-slate-600 dark:text-gray-100 opacity-80;
    &:hover {
      @apply scale-110 opacity-100;
    }

    &:active {
      @apply scale-95;
    }
  }
}
