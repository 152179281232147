.adm .projects {
  .table__wrapper {
    @apply rounded-2xl overflow-auto;

    table {
      @apply w-full table-auto;

      thead th {
        @apply px-5 py-4 bg-slate-600 text-gray-50 dark:border-b-2 dark:border-b-slate-700;
      }

      tbody td {
        @apply px-5 py-4 bg-gray-100 dark:bg-slate-600;

        .checkbox-icon {
          @apply my-0 mx-auto;
        }

        &:last-child {
          a {
            &:hover {
              @apply underline;
            }

            &:active {
              @apply scale-95;
            }
          }
        }
      }

      tbody tr {
        &:hover {
          td {
            @apply bg-gray-200 dark:bg-slate-700 transition-colors;
          }
        }
      }
    }
  }

  .project {
    &__title {
      @apply my-3 text-4xl;
    }

    &__form {
      .input-group {
        @apply mb-4;
      }
    }
  }
}