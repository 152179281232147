.todo,
.home {
  .input-group {
    label {
      @apply block mb-2 text-sm;
    }

    input {
      @apply px-3.5 py-2.5 bg-gray-50 dark:bg-slate-300 border border-gray-300 dark:border-transparent text-gray-900 text-sm rounded-lg block w-full transition-all;
      &.alert {
        @apply border-red-500;
        &:focus {
          @apply ring-red-500 border-red-500;
        }
      }

      &:focus {
        @apply ring-slate-500 border-slate-500;
      }

      &.scale:active {
        @apply scale-95;
      }
    }

    & + small {
      @apply block text-red-500 font-light;
    }
  }
}