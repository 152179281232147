.home {
  @apply bg-transparent;
  .rcs-inner-handle {
    @apply bg-gray-500 opacity-40;
  }

  .home__wrapper {
    @apply max-h-screen;

    .my-button {
      @apply py-5 px-12 bg-red-500 text-xl font-bold tracking-wider rounded-3xl;
      &:not(:disabled):hover {
        @apply bg-red-600;
      }
    }

    .header {
      @apply p-6 bg-gradient-to-b from-red-200 to-red-100;
      &__wrapper {
        @apply flex justify-between items-center;
      }

      &__logo {
        img {
          @apply h-24 drop-shadow-lg transition-transform;
          &:hover {
            @apply scale-110;
          }
        }
      }
    }

    .intro {
      @apply h-screen text-center text-slate-900 bg-gradient-to-b from-red-100 to-white;
      height: calc(100vh - theme('spacing.40'));

      &__wrapper {
        @apply h-full flex flex-col justify-center;
      }

      h1 {
        @apply mb-6 mx-auto w-fit relative text-7xl font-bold transition-all cursor-default;
        &:hover {
          @apply scale-110 -translate-y-3;
        }

        &::before {
          @apply w-full h-1 block absolute content-[''] -bottom-5 bg-slate-800 scale-x-0 transition-transform;
          &:hover {
            @apply scale-x-100;
          }
        }
      }

      h2 {
        @apply mx-72 mb-12 text-2xl;
      }
    }

    .images {
      &__block {
        @apply h-screen px-80 py-16 flex items-center justify-between;
        img,
        video {
          @apply shadow-xl rounded-2xl transition-all;
          &:hover {
            @apply rounded-3xl scale-105 shadow-2xl -translate-y-5;
          }
        }

        p {
          @apply w-1/2 text-5xl font-bold tracking-wide opacity-90;
        }

        &:first-child {
          @apply bg-gradient-to-b from-white to-amber-100;
          p {
            @apply text-amber-500;
          }

          img {
            @apply w-1/3 me-16;
          }
        }

        &:nth-child(2) {
          @apply pe-32 bg-gradient-to-b from-amber-100 to-pink-100;
          p {
            @apply text-pink-500;
          }

          img {
            @apply w-1/2 ms-16;
          }
        }

        &:nth-child(3) {
          @apply ps-40 pb-64 bg-gradient-to-b from-pink-100 to-blue-100;
          p {
            @apply text-blue-500;
          }

          img {
            @apply w-1/2 me-24;
          }
        }

        &:nth-child(4) {
          @apply bg-gradient-to-b from-blue-100 to-slate-100;
          p {
            @apply text-slate-700;
          }

          video {
            @apply w-2/5 ms-16;
          }
        }
      }
    }

    .footer {
      @apply pt-10 bg-gradient-to-b from-slate-100 from-0% to-slate-400;
      &__wrapper {
        @apply h-32 flex justify-between items-center;
      }

      &__logo {
        img {
          @apply h-10;
        }
      }
    }
  }
}
